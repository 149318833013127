(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

$(document).ready(function () {
  //console.log('...');
  $('a[data-fancybox]').fancybox({
    animationEffect: "fade"
  });
  $('.main-services .owl-carousel').owlCarousel({
    items: 1,
    nav: true,
    dots: false,
    transitionStyle: "fade",
    smartSpeed: 700,
    margin: 28,
    loop: true,
    //mouseDrag:false,
    responsive: {
      0: {
        items: 1,
        slideBy: 1
      },
      576: {
        items: 2,
        slideBy: 2
      },
      991: {
        items: 3,
        slideBy: 3
      },
      1200: {
        items: 4,
        slideBy: 4
      }
    }
  });
  $('.count').each(function () {
    $(this).prop('Counter', 0).animate({
      Counter: $(this).text()
    }, {
      duration: 3000,
      easing: 'linear',
      step: function step(now) {
        $(this).text(Math.ceil(now));
      }
    });
  });

  if ($('#map').length > 0) {
    mapboxgl.accessToken = 'pk.eyJ1IjoiYWdlbmN5bW9vbiIsImEiOiJja2xyM211MXUxMXJrMnVud2RtM29ka2p2In0.jCNNfWIhd_LBhxrMe46imw';
    var map = new mapboxgl.Map({
      container: 'map',
      // container ID
      style: 'mapbox://styles/agencymoon/cklr3r3bv04wp17lq47lcuiwp',
      // style URL
      center: [43.758836, 36.330964],
      // starting position [lng, lat]
      zoom: 9 // starting zoom

    });
    var marker = new mapboxgl.Marker({
      color: "#f2ca10",
      draggable: true
    }).setLngLat([43.758836, 36.330964]).addTo(map);
  }
});

},{}]},{},[1]);
